<template>
  <base-material-card
    color="primary"
    icon="mdi-sigma"
    inline
    class="px-5 py-3"
  >
    <template v-slot:after-heading>
      <div class="display-1 font-weight-light">Valores</div>
    </template>
    <v-container
      fluid
      class="py-0"
    >
      <v-row
        align="center"
        justify="start"
        class="mt-2"
      >
        <draggable
          v-bind="dragOptions"
          v-model="camposSelecionados"
          handle="#dragHandle"
        >
          <v-chip
            v-for="(campoSelecionado, i) in camposSelecionados"
            :key="i"
            class="d-inline-flex shrink ma-1"
            :disabled="loading"
            color="grey lighten-3"
            close
            @click:close="camposSelecionados.splice(i, 1)"
          >
            <v-icon
              id="dragHandle"
              style="cursor: move"
            >
              mdi-reorder-horizontal
            </v-icon>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="black"
                  class="px-2 ml-1"
                  min-width="0"
                  small
                  text
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left>mdi-menu-down</v-icon>
                  {{ campoSelecionado.function.toUpperCase() }} |
                  {{ campoSelecionado.column.toUpperCase() }}
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="(item, index) in dropdownitemsComputed(
                    campoSelecionado.type
                  )"
                  :key="index"
                  @click="campoSelecionado.function = item.function"
                >
                  <v-list-item-title>
                    {{ item.function }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-chip>
        </draggable>
      </v-row>
      <v-row class="mt-n5">
        <v-col
          md="5"
          cols="12"
        >
          <v-text-field
            ref="search"
            v-model="search"
            hide-details
            prepend-icon="mdi-magnify"
            label="Pesquisar campo"
            single-line
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          md="6"
          cols="12"
          class="ml-n3 mt-n5"
        >
          <v-checkbox
            v-model="todosCheckbox"
            @change="camposSelecionados = []"
            v-if="selecionarTodos"
          >
            <template v-slot:label>
              <span class="black--text">Selecionar todos os campos</span>
            </template>
          </v-checkbox>
          <div v-else>&nbsp;</div>
        </v-col>
      </v-row>
    </v-container>
    <div
      class="transitioncard mt-n5"
      :style="{ overflow: 'hidden', 'max-height': chipContainerHeight }"
    >
      <template v-for="campo in camposFiltrados">
        <v-chip
          class="mr-1 mt-1"
          outlined
          :key="campo.key"
          :disabled="loading || todosCheckbox"
          @click="pushChip(campo)"
        >
          {{ campo.column | toUpperCase }}
        </v-chip>
      </template>
    </div>
    <div
      justify="center"
      align="center"
      class="mt-n3"
    >
      <v-btn
        v-if="chipContainerHeight != '100%' && campos.length > 0"
        class="mt-2 mb-n3 ml-n7"
        icon
        color="gray"
        @click="chipContainerHeight = '100%'"
      >
        <v-icon>mdi-chevron-down</v-icon>
      </v-btn>
      <v-btn
        v-else-if="campos.length > 0"
        class="mt-2 mb-n3 ml-n7"
        icon
        color="gray"
        @click="chipContainerHeight = '37px'"
      >
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
    </div>
  </base-material-card>
</template>

<script>
export default {
  components: {
    draggable: () => import('vuedraggable')
  },

  props: {
    campos: {
      type: Array,
      default: () => []
    },
    relatorio: {
      type: Object,
      default: () => {}
    },
    selecionarTodos: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    dropdownitems: [
      {
        function: 'SOMAR',
        types: ['smallint', 'integer', 'bigint', 'numeric']
      },
      {
        function: 'MEDIA',
        types: ['smallint', 'integer', 'bigint', 'numeric']
      },
      {
        function: 'CONTAR',
        types: [
          'smallint',
          'integer',
          'bigint',
          'character varying',
          'text',
          'datetime',
          'timestamp'
        ]
      },
      {
        function: 'MÍNIMO',
        types: ['smallint', 'integer', 'bigint', 'numeric', 'date', 'timestamp']
      },
      {
        function: 'MÁXIMO',
        types: ['smallint', 'integer', 'bigint', 'numeric', 'date', 'timestamp']
      }
    ],
    loading: false,
    search: '',
    camposSelecionados: [],
    todosCheckbox: false,
    chipContainerIsExpanded: false,
    chipContainerHeight: '37px',
    dragOptions: { animation: 250, ghostClass: 'ghost' }
  }),

  computed: {
    camposFiltrados() {
      const search = this.search.toLowerCase();
      if (!search) return this.campos;

      return this.campos.filter((campo) =>
        campo.column.toLowerCase().includes(search)
      );
    }
  },

  methods: {
    pushChip(item, funcao = false) {
      if (!funcao) {
        if (['smallint', 'integer', 'bigint', 'numeric'].includes(item.type)) {
          item.function = 'SOMAR';
        } else if (['date', 'timestamp'].includes(item.type)) {
          item.function = 'MÁXIMO';
        } else {
          item.function = 'CONTAR';
        }
      } else {
        switch (funcao) {
          case 'SUM':
            item.function = 'SOMAR';
            break;
          case 'MIN':
            item.function = 'MÍNIMO';
            break;
          case 'MAX':
            item.function = 'MÁXIMO';
            break;
          case 'AVG':
            item.function = 'MÉDIA';
            break;
          default:
            item.function = 'CONTAR';
            break;
        }
      }

      const clone = {
        column: item.column,
        function: item.function,
        type: item.type
      };
      this.camposSelecionados.push(clone);
    },

    dropdownitemsComputed(type) {
      return this.dropdownitems.filter((item) => item.types.includes(type));
    },

    exportCampos() {
      if (this.todosCheckbox) {
        return JSON.parse(JSON.stringify(this.campos));
      } else {
        return JSON.parse(JSON.stringify(this.camposSelecionados));
      }
    },

    getRelatorioCampos() {
      /* 
        * Caso o selecionar todos campos tiver sido marcado a funcao será atribuida como vazio = "", logo != "AGRUPAR" irá 
          se enquadrar nessa regra e os valores serão atributos a const campos.
        * OBS: Alguns campos ficarão de fora pois no arquivo Edit.vue tem uma função com o nome filtraCamposValores que 
          retira duplicatas entre campos linhas e campos valores.

        A const campos irá armazenar campos valores selecionados nos campos valores porem que não tem correspondencia ou que 
        não foram selecionados nos campos linhas
      */
      const campos = JSON.parse(this.relatorio.estrutura_json).campos.filter(
        (campo) => campo.funcao != 'AGRUPAR'
      );

      /*
        * A const camposDuplicadosExcluidos irá armazenar campos valores selecionados nos campos valores independente de ter 
          correspondencia ou se tiverem sido selecionados nos campos linhas
      */
      const camposDuplicadosExcluidos = JSON.parse(
        this.relatorio.estrutura_json
      ).campos.filter(
        (campo) =>
          campo.funcao == 'AGRUPAR' &&
          this.campos.some((v) => v.column == campo.campo)
      );

      /* const totalCamposValoresSelecionados irá armazenar o total da soma dos campos valores selecionados */
      const totalCamposValoresSelecionados =
        campos.length + camposDuplicadosExcluidos.length;

      if (
        totalCamposValoresSelecionados === this.campos.length &&
        campos.every((val) => val.funcao === '')
      ) {
        this.todosCheckbox = true;
      } else {
        campos.forEach((campo) => {
          this.campos.forEach((item) => {
            if (campo.campo === item.column) this.pushChip(item, campo.funcao);
          });
        });
      }
    }
  },

  watch: {
    campos() {
      this.camposSelecionados = [];
      this.todosCheckbox = false;

      if (Object.keys(this.relatorio).length !== 0) this.getRelatorioCampos();
    }
  }
};
</script>
